import AuthLayout from '@/components/layouts/AuthLayout'
import CommonLayout from '@/components/layouts/commonLayout'
import channelRouter from '@/router/channel'

const constantRouter = [
  {
    path: '/redirect',
    component: () => import('@/views/dashboard/Index'),
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/marketing',
    name: 'marketing',
    meta: { title: 'marketing' },
    component: CommonLayout,
    hidden: true,
    children: [
      {
        path: 'unsubscribe',
        name: 'Unsubscribe',
        meta: { title: 'Unsubscribe' },
        component: () => import(/* webpackChunkName: "Unsubscribe" */ '@/views/marketing/unsubscribe.vue')
      }
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    meta: { title: 'Login' },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: { title: 'Login' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/login.vue')
      },
      {
        path: 'emailauth',
        name: 'emailauth',
        meta: { title: 'Email Auth' },
        component: () => import(/* webpackChunkName: "emailauth" */ '@/views/auth/emailauth.vue')
      },
      {
        path: 'register',
        name: 'register',
        meta: { title: 'Register' },
        component: () => import(/* webpackChunkName: "register" */ '@/views/auth/login.vue')
      },
      {
        path: 'subscription',
        name: 'subscription',
        meta: { title: 'Channels' },
        component: () => import(/* webpackChunkName: "subscription" */ '@/views/auth/subscription.vue')
      },
      {
        path: 'payment',
        name: 'payment',
        meta: { title: 'Payment' },
        component: () => import(/* webpackChunkName: "Payment" */ '@/views/auth/payment.vue')
      },
      {
        path: 'mychannel',
        name: 'mychannel',
        meta: { title: 'MyChannel' },
        component: () => import(/* webpackChunkName: "MyChannel" */ '@/views/auth/myChannel.vue')
      },
      {
        path: 'settings',
        name: 'settings',
        meta: { title: 'Settings' },
        component: () => import(/* webpackChunkName: "Settings" */ '@/views/auth/settings.vue')
      },
      {
        path: 'orders',
        name: 'orders',
        meta: { title: 'Billing' },
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/auth/orders.vue')
      },
      {
        path: 'invoices',
        name: 'invoices',
        meta: { title: 'Invoices' },
        component: () => import(/* webpackChunkName: "Invoices" */ '@/views/auth/invoices.vue')
      },
      {
        path: 'trialextend',
        name: 'trialextend',
        meta: { title: 'trialextend' },
        component: () => import(/* webpackChunkName: "trialextend" */ '@/views/auth/trialExtend.vue')
      },
      {
        path: 'resetpwd',
        name: 'resetpwd',
        meta: { title: 'Reset Password' },
        component: () => import(/* webpackChunkName: "resetpwd" */ '@/views/auth/resetpwd.vue')
      },
      {
        path: 'addpayment',
        name: 'AddPayment',
        meta: { title: 'Add Payment' },
        component: () => import(/* webpackChunkName: "addpayment" */ '@/views/auth/addPayment.vue')
      },
      {
        path: 'installapp',
        name: 'InstallApp',
        meta: { title: 'Install App' },
        component: () => import(/* webpackChunkName: "installapp" */ '@/views/auth/installapp.vue')
      },
      {
        path: 'cards',
        name: 'Cards',
        meta: { title: 'Cards' },
        component: () => import(/* webpackChunkName: "Cards" */ '@/views/auth/cards.vue')
      },
      {
        path: 'survey',
        name: 'Survey',
        meta: { title: 'Survey' },
        component: () => import(/* webpackChunkName: "Survey" */ '@/views/auth/survey.vue')
      },
      {
        path: 'profile',
        name: 'Profile',
        meta: { title: 'Setup Profile' },
        component: () => import(/* webpackChunkName: "Profile" */ '@/views/auth/profile.vue')
      },
      {
        path: 'returnurl',
        name: 'ReturnURL',
        meta: { title: 'Returning' },
        component: () => import(/* webpackChunkName: "ReturnURL" */ '@/views/auth/returnurl.vue')
      },
      {
        path: 'referral',
        name: 'Referral',
        meta: { title: 'Referral Info' },
        component: () => import(/* webpackChunkName: "Referral" */ '@/views/auth/referral.vue')
      },
      {
        path: 'pointshistory',
        name: 'PointsHistory',
        meta: { title: 'Points History' },
        component: () => import(/* webpackChunkName: "PointsHistory" */ '@/views/auth/pointsHistory.vue')
      },
      {
        path: 'pointsredeem',
        name: 'PointsRedeem',
        meta: { title: 'Points exchange' },
        component: () => import(/* webpackChunkName: "PointsRedeem" */ '@/views/auth/redeem.vue')
      },
      {
        path: 'accounts',
        name: 'Accounts',
        meta: { title: 'Account List' },
        component: () => import(/* webpackChunkName: "AccountList" */ '@/views/auth/account.vue')
      },
      {
        path: 'wallet',
        name: 'MyWallet',
        meta: { title: 'Day Pass Wallet' },
        component: () => import(/* webpackChunkName: "MyWallet" */ '@/views/auth/wallet.vue')
      },
      {
        path: 'cancelsub',
        name: 'CancelSub',
        meta: { title: 'Cancel Subscription' },
        component: () => import(/* webpackChunkName: "CancelSub" */ '@/views/auth/cancelSub.vue')
      }
    ]
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { title: 'SignUp' },
    component: AuthLayout,
    hidden: true,
    children: [
      {
        path: 'subplan',
        name: 'SubPlan',
        meta: { title: 'SubPlan' },
        component: () => import(/* webpackChunkName: "SubPlan" */ '@/views/auth/signup/subPlan.vue')
      },
      {
        path: 'creditoption',
        name: 'CreditOption',
        meta: { title: 'CreditOption' },
        component: () => import(/* webpackChunkName: "CreditOption" */ '@/views/auth/signup/creditoption.vue')
      }
    ]
  }
].concat(channelRouter)

export default constantRouter
